import React from "react"
import LittleFeetLogo from "../images/logo_littlefeet.jpg"
import PortsLogo from "../images/logo_portsofjersey.png"
import { headerStyle } from '../style/headerStyle';
import { Link } from "gatsby"
const PageHeaderLF = () => {
  return (
    <div css={headerStyle}>
      <div className="grid">
        <div className="col-1-1 fl_header" id="sitelogo">
        
          <Link to="/" ><img src={PortsLogo} alt="Port of Jersey" id="logo" /></Link>
            
          <div id='fl_title'><span>Jersey beach clean</span></div>
        </div>        
      </div>
    </div>
  )
}

export default PageHeaderLF
