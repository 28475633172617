import { css } from "@emotion/core";
export const globalStyles =  css`
 /* simple grid */
 #s4-bodyContainer *, #s4-bodyContainer *:after, #s4-bodyContainer *:before {
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
            }

            body {
              margin: 0px;
            }

            [class*='col-'] {
              float: left;
              /* column-space */
            }

            .grid {
              width: 100%;
              max-width: 1140px;
              min-width: 755px;
              margin: 0 auto;
              overflow: hidden;
            }

            .grid:after {
              content: "";
              display: table;
              clear: both;
            }

            .grid-pad {
              padding-top: 20px;
              padding-left: 20px; /* grid-space to left */
              padding-right: 0px; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-20px=0 */
            }

          
            /* Content Columns */

            .col-1-1 {
              width: 100%;
            }
            .col-2-3, .col-8-12 {
              width: 66.66%;
            }

            .col-1-2, .col-6-12 {
              width: 50%;
            }

            .col-1-3, .col-4-12 {
              width: 33.33%;
            }

            .col-1-4, .col-3-12 {
              width: 25%;
            }

            .col-1-5 {
              width: 20%;
            }

            .col-1-6, .col-2-12 {
              width: 16.667%;
            }

            .col-1-7 {
              width: 14.28%;
            }

            .col-1-8 {
              width: 12.5%;
            }

            .col-1-9 {
              width: 11.1%;
            }

            .col-1-10 {
              width: 10%;
            }

            .col-1-11 {
              width: 9.09%;
            }

            .col-1-12 {
              width: 8.33%
            }

            /* Layout Columns */

            .col-11-12 {
              width: 91.66%
            }

            .col-10-12 {
              width: 83.333%;
            }

            .col-9-12 {
              width: 75%;
            }

            .col-5-12 {
              width: 41.66%;
            }

            .col-7-12 {
              width: 58.33%
            }

            @media handheld, only screen and (max-width: 767px) {
              .grid {
                width: 100%;
                min-width: 0;
                margin-left: 0px;
                margin-right: 0px;
                /*padding-left: 20px; grid-space to left */
            /*    padding-right: 10px; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px */
              }
              
              [class*='col-'] {
                width: auto;
                float: none;
                margin-left: 0px;
                margin-right: 0px;
                /* this addds margin to the top in tablet/mobile  margin-top: 10px; */
                margin-bottom: 10px;
                padding-left: 0px;
                padding-right: 10px; /* column-space */
              }
              
              
              /* Mobile Layout */
              
              [class*='mobile-col-'] {
                float: left;
                margin-left: 0px;
                margin-right: 0px;
                margin-top: 0px;
                margin-bottom: 10px;
                padding-left: 0px;
                padding-right: 10px; /* column-space */
                padding-bottom: 0px;
              }
              
              .mobile-col-1-1 {
                width: 100%;
              }
              .mobile-col-2-3, .mobile-col-8-12 {
                width: 66.66%;
              }

              .mobile-col-1-2, .mobile-col-6-12 {
                width: 50%;
              }

              .mobile-col-1-3, .mobile-col-4-12 {
                width: 33.33%;
              }

              .mobile-col-1-4, .mobile-col-3-12 {
                width: 25%;
              }

              .mobile-col-1-5 {
                width: 20%;
              }

              .mobile-col-1-6, .mobile-col-2-12 {
                width: 16.667%;
              }

              .mobile-col-1-7 {
                width: 14.28%;
              }

              .mobile-col-1-8 {
                width: 12.5%;
              }

              .mobile-col-1-9 {
                width: 11.1%;
              }

              .mobile-col-1-10 {
                width: 10%;
              }

              .mobile-col-1-11 {
                width: 9.09%;
              }

              .mobile-col-1-12 {
                width: 8.33%
              }

              /* Layout Columns */

              .mobile-col-11-12 {
                width: 91.66%
              }

              .mobile-col-10-12 {
                width: 83.333%;
              }

              .mobile-col-9-12 {
                width: 75%;
              }

              .mobile-col-5-12 {
                width: 41.66%;
              }

              .mobile-col-7-12 {
                width: 58.33%
              }
              
              .hide-on-mobile {
                display: none !important;
                width: 0;
                height: 0;
              }
            }

            @media (min-width:700px ) {
              .marginheader * {
                margin-bottom: 2px;
              }
            }

            @media (min-width:960px ) {
              .marginheader * {
                margin-bottom: 0px;
              }
            }
            .push-right {
              float: right;
            }
            
            .push-left {
                float:left;
            }

        
              /* global */
            
            body {
                background: #fff;
                overflow: auto !important;
                font-family:"Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
                font-size: 13px;
            }

            #s4-workspace {
              overflow-y: auto !important;
              background-color: transparent;
              width: 100%;
            margin-bottom:-30px;
          }

            .s4-bodyContainer {
              font-family: Tahoma, Verdana, Arial, Sans-serif !important;
              margin: 0;
              padding: 0;
              font-size:14px;
            /* overflow: auto !important;  Added on 26-11-2012 */
          }
          
          @media (max-width:820px) {
              .s4-bodyContainer {
                  font-size:16px;
              }
          
              .s4-bodyContainer h4 {
                  font-size:16px;
              }
          
              .s4-bodyContainer h3 {
                  font-size:18px;
              }
          }
          #ContentWrapper {
              margin: 10px auto 0px;
          }
          
          #contentRow {
              padding-top: 0px;
          }

          
a {
  color: #555;
}

a:visited {
  color:#555;
}
  h2 a:visited {
        color:#555
  }
  a:hover {
      color: #000;
  }

  a img {
      border: none;
  }


/*SP2010 style added*/
h1, h2, h3, h4, .ms-rteElement-H1, .ms-rteElement-H2, .ms-rteElement-H3, .ms-rteElement-H4, .ms-rtestate-field h1, h1.ms-rteElement-H1, .ms-rtestate-field h2, h2.ms-rteElement-H2 {
  margin: 1.6em 0 0.3em 0;
  color: #004469;
  font-style: normal;
  font-family: Tahoma,Verdana,Arial,Sans-serif !important;
}

h1, .ms-rteElement-H1 {
  margin-top: 0;
}

p, .ms-rteElement-P, .ms-rtestate-field p, p.ms-rteElement-P {
  margin: 0.6em 0;
  line-height: 20px;
  font-size:14px;
  color: inherit;
}

li {
  line-height: 20px;
  
}

h1, .ms-rteElement-H1 {
  font-size: 34px;
  font-weight: normal;
}

h2, .ms-rteElement-H2 {
  font-size: 20px;
  font-weight: bold;
}

h3, .ms-rteElement-H3, .ms-rtestate-field h3, h3.ms-rteElement-H3 {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-top: 0.8em;
}

h4, .ms-rteElement-H4, .ms-rtestate-field h4, h4.ms-rteElement-H4 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #000;
  clear:both;
}

h4 img {
  max-width:168px;
}

.ms-asset-icon {
  margin: 0;
}

.ms-rtePosition-4 {
  vertical-align: inherit;
}

.clear {
  display: block;
  clear: both;
  width: auto;
}

.large {
  font-size: 120%;
}

.larger {
  font-size: 140%;
}

.hidden {
  display: none;
}

.unhidden {
  display: block;
}
#sectionPage 
{ 
  img
  {
  max-width:100%;
  }
  .banner img {
    width: 100%;
    height: auto;
    @media (max-width: 820px) {
      
        max-width:320px;
        margin:0 auto;
    }
  }
}

  img.fullImage{
    max-width:100%;
  }
  .btn_close{
    float:right;
    font-weight:700;
    background-color:transparent;
    border-style:none;
    margin-top:10px;
  }
`;