import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Links from '../components/Links'

const GetHarbourMenu = () => {
  const data = useStaticQuery(graphql`
    query GetHarbourMenu {
        wpgraphql{
          menu(id: "Harbours Main Menu", idType: NAME) {
            id
            name
            menuItems(where: {parentId: null},first: 100){
              nodes {
                id
                url
                parentId
                label
                childItems {
                  nodes {
                    url
                    label
                  }
                }
              }
            }
          }
          }
    }
  `);

  var mainMenu=data.wpgraphql.menu.menuItems.nodes;
  var currentURL = "";
  if (typeof window !== 'undefined') {
    currentURL=window.location.href; 
  }
return(
<ul>
           {mainMenu.map(( node ) => (
             node.parentId === null&&
            <li key={node.id}>
              <Links to={node.url} name={node.label}></Links>
              <ul className={currentURL.indexOf(node.url.toLowerCase().replace("https://","").replace("portstest.azurewebsites.net","").replace("pojcontentlinux.azurewebsites.net","").replace("pojcontent.azurewebsites.net","").replace("portstest-linux.azurewebsites.net","").replace("/ports","").replace("/jerseyairport","")) !== -1 ? "tertiary current" : "tertiary" }>                
              {node.childItems.nodes.map(( node ) => (
                  <li key={node.id}>
                  <Links to={node.url} name={node.label }></Links>
                </li>
                ))}
              </ul>
            </li>
          ))} 

         </ul>
)};

export default GetHarbourMenu;
