import { css } from "@emotion/core"
import buttonSprite from "../images/button-sprite-sheet.png"

export const headerStyle = css`

@media print {
  display:none
}

  .sitebuttons {
    margin-top: 15px;
    ul {
      margin: 0;
      padding: 0;
      text-align: right;
    }
    li {
      list-style-type: none;
      display: inline;
      width: 25%;
      margin-left: 0px;
      margin-top: 10px;
      padding-right: 10px;
      float: left;
    }
  }

  .nav-tab {
    height: 55px;
    float: left;
    width: 100%;
    &:hover span.button-left {
      background: url(${buttonSprite}) -50px -100px ;
    }
    &:hover span.button-right,
    .navtab.active .button-right {
      background: url(${buttonSprite}) -400px -100px;
    }
    
  }
  .active span.button-left {
    background: url(${buttonSprite}) -50px -100px !important;
  }
  .active span.button-right {
      background: url(${buttonSprite}) -400px -100px !important;
  }

  .button-left {
    height: 55px;
    width: 100%;
    background: url(${buttonSprite}) -50px 0px;
    display: inline-block;
    color: #ffffff;
    float: left;
    position: relative;
    p {
      width: 100%;
      text-align: center;
      float: left;
      line-height: 55px;
      margin: 0;
      box-sizing: border-box;
      display: block;
      font-size: 15px;
    }
  }

  .button-right {
    height: 55px;
    width: 8px;
    background: url(${buttonSprite}) -400px 0px;
    display: inline-block;
    top: 0px;
    right: 0px;
    position: absolute;
  }

  .portflag,
  .marinaflag,
  .coastguardflag,
  .airportflag {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 0px;
    left: 0px;
    display: inline-block;
  }

  .portflag {
    background: url(${buttonSprite}) 0px -132px;
  }

  .coastguardflag {
    background: url(${buttonSprite}) 0px -44px;
  }

  .airportflag {
    background: url(${buttonSprite}) 0px 0px;
  }

  .marinaflag {
    background: url(${buttonSprite}) 0px -88px;
  }

  &#PdfviewerPage{
    background-color:#fff;
    position: absolute;
    width: 100%;
    display:none;
  }
  #sitelogo {
    height: auto;
    display: inline-block;
    vertical-align: middle;
    padding-left: 8px;
    &.pdf_header{
      height:40px;
      background-color:#fff;
      img{height:35px;}
    }
  }

  #logo {
    max-width: 100%;
    max-height: 100%;
    max-height: 100px;
  }

  #sitelinks li {
    list-style-type: none;
    display: inline-block;
    margin-right: 0;
  }
  

  @media (max-width: 768px) {
    header .grid {
      padding-right: 0;
    }
    #toplinks {
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    #sitelogo {
      width: 20%;
      float: left;
    }
    .sitebuttons {
      width: 80%;
      float: left;
      padding-left: 10px;
    }

    .button-left {
      height: 40px;
      background: url(${buttonSprite}) -50px -200px;
      p {
        line-height: 40px;
      }
    }

    .button-right {
      height: 40px;
      background: url(${buttonSprite}) -400px -200px;
    }

    .nav-tab {
      &:hover span.button-left,
      .active .button-left {
        background: url(${buttonSprite}) -50px -300px;
      }
      &:hover span.button-right,
      .active .button-right {
        background: url(${buttonSprite}) -400px -300px;
      }
    }

    .portflag,
    .marinaflag,
    .coastguardflag,
    .airportflag {
      height: 15px;
      width: 15px;
    }

    .portflag {
      background: url(${buttonSprite}) 0px -333px;
    }

    .coastguardflag {
      background: url(${buttonSprite}) 0px -245px;
    }

    .airportflag {
      background: url(${buttonSprite}) 0px -200px;
    }

    .marinaflag {
      background: url(${buttonSprite}) 0px -289px;
    }
  }

  @media (max-width: 760px) {
    #sitelogo {
      float: none;
      width: 100%;
    }

    #sitelinks .grid {
      padding-right: 0px;
    }

    .sitebuttons {
      width: 100%;
      float: none;
      clear: both;
      padding-right: 0px;
      margin-left: -5px;
      ul {
        text-align: center;
        padding-left: 10px;
      }
      li {
        width: 50%;
        padding-left: 0;
        padding-right: 10px;
        margin-bottom: 5px;
        margin-top: 0px;
      }
    }

    .button-left {
      height: 55px;
      background: url(${buttonSprite}) -50px -0px;
      width: 100%;
      p {
        padding-left: 0px;
        line-height: 55px;
        font-size: 14px;
      }
    }

    .button-right {
      height: 55px;
      background: url(${buttonSprite}) -400px -0px;
    }

    .nav-tab {
      &:hover span.button-left,
      .active .button-left {
        background: url(${buttonSprite}) -50px -100px;
      }
      &:hover span.button-right,
      .active .button-right {
        background: url(${buttonSprite}) -400px -100px;
      }
    }

    .portflag,
    .marinaflag,
    .coastguardflag,
    .airportflag {
      height: 20px;
      width: 20px;
    }

    .portflag {
      background: url(${buttonSprite}) 0px -132px;
    }

    .coastguardflag {
      background: url(${buttonSprite}) 0px -44px;
    }

    .airportflag {
      background: url(${buttonSprite}) 0px 0px;
    }

    .marinaflag {
      background: url(${buttonSprite}) 0px -88px;
    }
  }
  /*litle feet*/
  .lf_logo{
    position:relative;
  }
  .lf_logo >a{
   position:absolute;
   left:5px;
  }
  .fl_header{
    position:relative;
  }
  /*  .lf_logo >a:before{
    content:"Sponsored by: ";
    position:absolute;
    top:12px;
    left:0;
    color:#000;

   }
   */
  #fl_title{
  display: inline-block;
    bottom: 25px;
    position: absolute;
    font-size: 34px;
    padding-left: 40px;
    text-align: center;
    width: 40%;
    color: #004469;
  }
  .lf_logo >a img{
    max-height:100px;
    margin-top:0;
    margin-right:5px;
  }
  #LittleFeetLogo{
    width:600px;
    display:inline;
    font-size:34px;
    position:relative;
    padding-left:85px;
    padding-top:18px;
    float:left;
    margin-top:15px;
    margin-left:calc(50% - 180px);
    height:80px;

  }
 
  #LittleFeetLogo img{height:80px;position:absolute;top:-5px;left:0;}
  .hidden{
    display:none;
  }
  @media (max-width: 820px) {
    #sitelogo.fl_header{
      width:100%;
    }
    #fl_title{
      position:relative;
      display:block;
      width:100%;
      margin-top:10px;
      font-size:28px;
      padding-left:0;
    }
    #LittleFeetLogo{
      width:100%;
      display:block;
      height:80px;
      font-size:25px;
  }
  #sitelogo.lf_logo {
    /*height:130px;*/
  }
  .lf_logo >a{
    text-decoration:none;
    position:relative;
    width:100%;
    text-align:center;
    
    top:-25px;
   }
   lf_logo >a img{
    height:40px;
    margin-top:25px;
    margin-right:5px;
  }
   .lf_logo >a:before{
    position:relative;
    top:-25px;
    padding-right:10px;
   }
}
`
