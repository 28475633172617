import React from "react"
import { css } from "@emotion/core";
import { Link } from "gatsby"

const topBar = css`
  margin-top: 0px;
  background: #333333;
  color: #ffffff;
  font-size: 12px;
  height: 30px;
  width: 100%;
  margin: 0 auto;

  @media print {
    display:none
  }  

  #toplinks {
    text-align: right;
  }

  #toplinks a, #toplinks #ReciteButton {
    color: #ffffff;
    line-height: 25px;
    margin-right: 10px;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
  }

  #toplinks a:hover {
    text-decoration: underline;
  }
  #ReciteButton {
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: white;
    text-transform: uppercase;
  }
`;

const TopBar: React.FC = () => {
  return (
    <div id="topbar" css={topBar} >
      <div className="grid">
        <div className="col-1-1" id="toplinks">
          {process.env.GATSBY_SITE_MODE!=="AIRPORT" ?
            <Link to="/">PORTS.JE</Link>
          :
            <a href={process.env.GATSBY_PORTS_URL}>PORTS.JE</a>
          }
          {process.env.GATSBY_SITE_MODE!=="AIRPORT" ?
            <Link to="/about-us/contact">CONTACT</Link>
          :
            <a href={process.env.GATSBY_PORTS_URL + "about-us/contact"}>CONTACT</a>
          }
          <button id="ReciteButton">Accessibility Options</button>
        </div>
      </div>
    </div>
  )
}
export default TopBar
