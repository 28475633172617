import React from 'react'
import {Link} from 'gatsby'

const LinkFormat = (props) =>{
    if (typeof props.to !== 'undefined') {
        const replaceLink =props.to.toLowerCase().replace("http://"+process.env.GATSBY_WORDPRESS_PREVIEW,"").replace("https://"+process.env.GATSBY_WORDPRESS_PREVIEW,"").replace("/ports","").replace("/jerseyairport","").replace("/default","").replace("pojcdn.blob.core.windows.net","cdn.ports.je")
        if(props.to.indexOf(process.env.GATSBY_WORDPRESS_PREVIEW) > -1 ){ 
            if (props.name==="img"){
                return(<Link to={replaceLink} ><img alt='' src={encodeURI(props.img)}/></Link>)
            }
            if (props.name === "Pay online")
                return(<Link className="payOnline" to={replaceLink} dangerouslySetInnerHTML={{ __html: props.name }}></Link>)  
            else
                return(<Link to={replaceLink} dangerouslySetInnerHTML={{ __html: props.name }}></Link>)  
        }
        else if(props.to.indexOf('http') === -1){ 
            if (props.name==="img"){
                return(<Link to={replaceLink}><img alt='' src={encodeURI(props.img)}/></Link>)
            }
            let cssclass="port";
            if (replaceLink.indexOf("jerseycoastguard") !== -1)
                cssclass="coastguard";
            else if (replaceLink.indexOf("jerseymarinas") !== -1)
                cssclass="marinas";
            else if (replaceLink.indexOf("jerseyharbours") !== -1)
                cssclass="harbours";
            else if (replaceLink.indexOf("jerseyairport") !== -1)
                cssclass="airport";

            if(process.env.GATSBY_SITE_MODE==="AIRPORT")
                cssclass="airport";

            if(cssclass==="port")
            return(<Link to={replaceLink} dangerouslySetInnerHTML={{ __html: props.name }}></Link>)  
            else
            return(<Link className={cssclass} to={replaceLink} dangerouslySetInnerHTML={{ __html: props.name }}></Link>)  
        }
        else {
            if (props.name==="img"){
                return(<a href to={props.to.replace("pojcdn.blob.core.windows.net","cdn.ports.je")}><img alt='' src={encodeURI(props.img)}/></a>)
            }
                return(<a href={props.to.replace("pojcdn.blob.core.windows.net","cdn.ports.je")} target="_blank" rel="noopener noreferrer" >{props.name}</a>)
        }
    }
}
export default LinkFormat