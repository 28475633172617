import React, {useState} from "react"
import BtnMenu from "../images/btn_menu.png"
import BtnSearch from "../images/btn_search.png"
import { NavStyle } from '../style/navStyles';
import { useStaticQuery, graphql } from "gatsby"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Links from '../components/Links';

const NavBar= () => {
  const [menuToggle, setmenuToggle] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [fetchUrl, setFetchUrl] = useState("");
  const mediaMatch = useMediaQuery('(min-width:821px)');

  const urlString = `/results?q=${fetchUrl}`
 
  const toggleMenu = () => {
    setmenuToggle(!menuToggle);
  }

  const toggleSearchMenu = () => {
    setSearchToggle(!searchToggle);
  }

  const goToUrl = () => {
    window.location.href=urlString
  }


  let littleFeet =true;

  var currentURL = "";

  const data = useStaticQuery(graphql`
  query GetLFMenu {
      wpgraphql{
        menu(id: "Little Feet Menu", idType: NAME) {
          id
          name
          menuItems(where: {parentId: null},first: 100){
            nodes {
              id
              url
              parentId
              label
              childItems {
                nodes {
                  url
                  label
                }
              }
            }
          }
        }
        }
  }
  `);
  var mainMenu=data.wpgraphql.menu.menuItems.nodes;
  
  return (
    <nav css={NavStyle} >
    <div className="">
      <div id="mobilemenu" className="handheld">
        <button className="menuButtonStyle" onClick={toggleMenu}>
          <img alt="" src={BtnMenu} className="menuButtonStyleImage" />
        </button>      
      </div>
      <div className="handheld push-right" id="mobilesearch">
        <button className="menuButtonStyle" onClick={toggleSearchMenu}>
          <img alt="" src={BtnSearch} className="menuButtonStyleImage" />
        </button>     
      </div>
        {searchToggle  ? (
          <div id="mobilesearchbox" className="handheld">
          <input
            type="text"
            className="field"
            id="txtmobileSearch"
            name="txtmobileSearch"
            placeholder="Search..."
            style={{fontSize:"11px", paddingLeft:"5px"}}
            onChange={(event) => setFetchUrl(event.target.value)}
          />
          <button
            onClick={goToUrl}
            id="btnMobileSearch"
            name="btnMobileSearch"
          >
            Search
          </button>
        </div>
        ) : (
          null
        )}
       
        {menuToggle || mediaMatch  ? (
        <div id="mainlinks" className="col-1-1" style={{display:"block"}}>
         <ul>
           {mainMenu.map(( node ) => (
            <li key={node.id}>
              <Links to={node.url} name={node.label}></Links>
               <ul className={currentURL.indexOf(node.url.toLowerCase().replace("https://","").replace('portstest.azurewebsites.net',"").replace("pojcontent.azurewebsites.net","").replace("pojcontentlinux.azurewebsites.net","").replace("/ports","").replace("/jerseyairport","")) !== -1 ? "tertiary current" : "tertiary" }>
                {node.childItems.nodes.map(( node ) => (
                  <li key={node.id}>
                    <Links to={node.url} name={node.label }></Links>                  
                </li>
                ))}
              </ul>
            </li>
          ))} 

         </ul>
       </div>       
       ) : (
          null
        )}
        </div>
  </nav>
  )
}
export default NavBar;
