import React from "react"
import PortsLogo from "../images/logo_portsofjersey.png"
import HarboursLogo from "../images/logo_jerseyharbours.png"
import MarinasLogo from "../images/logo_jerseymarinas.png"
import CoastGuardLogo from "../images/logo_jerseycoastguard.png"
import AirportLogo from "../images/logo_jerseyairport.png"
import { headerStyle } from '../style/headerStyle';
import { Link } from "gatsby"

const PageHeader = () => {
  let ports = false
  let harbours = false
  let marinas = false
  let coastguard = false
  let airport = false;

  let marinasClass="nav-tab";
  let harboursClass="nav-tab";
  let coastguardClass="nav-tab";

  if (typeof window !== 'undefined') {
    if (window.location.href.indexOf("jerseycoastguard") !== -1) {
      coastguard=true;
      coastguardClass="nav-tab active"
    }
    else if (window.location.href.indexOf("jerseymarinas") !== -1) {
      marinas=true;
      marinasClass="nav-tab active"
    }
    else if (window.location.href.indexOf("jerseyharbours") !== -1) {
      harbours=true;
      harboursClass="nav-tab active"
    }
    else
      ports=true;


    if (window.location.href.indexOf("jerseymarinas/boatowners/onlineinvoices") !== -1) {
      ports=true;
      marinas=false;
      marinasClass="nav-tab active"
    }
  
  }

   
  if(process.env.GATSBY_SITE_MODE==="AIRPORT"){
    airport=true;
    ports=false;
  }
  
  return (
    <React.Fragment>
      <div css={headerStyle} id="sitelinks" className="noindex marginheader">
        <div className="grid">
          <div id="sitelogo" className="col-3-12">
            {coastguard && <Link to="/jerseycoastguard" ><img src={CoastGuardLogo} alt="Jersey Coastguard" id="logo" /></Link> }
            {ports && <Link to="/" ><img src={PortsLogo} alt="Port of Jersey" id="logo" /></Link> }
            {marinas && <Link to="/jerseymarinas" ><img src={MarinasLogo} alt="Jersey Marinas" id="logo" /></Link> }
            {harbours && <Link to="/jerseyharbours" ><img src={HarboursLogo} alt="Jersey Harbours" id="logo" /></Link> }
            {airport && <Link to="/" ><img src={AirportLogo} alt="Jersey Airport" id="logo" /></Link> }
          </div>
          <div className="sitebuttons col-9-12">
            <ul>
              <li>
                {process.env.GATSBY_SITE_MODE!=="AIRPORT" ?
                  <Link
                    id="port"
                    className={harboursClass}
                    to="/jerseyharbours/"
                    title="Jersey Harbours"
                  >
                    <span className="button-left">
                      <span className="portflag"></span>
                      <p>Jersey Harbours</p>
                      <span className="button-right"></span>
                    </span>
                  </Link>
                :
                  <a
                    id="port"
                    className="nav-tab"
                    href={process.env.GATSBY_PORTS_URL+"jerseyharbours/"}
                    title="Jersey Harbours"
                  >
                    <span className="button-left">
                      <span className="portflag"></span>
                      <p>Jersey Harbours</p>
                      <span className="button-right"></span>
                    </span>
                  </a>
                }
              </li>
              <li>
                {process.env.GATSBY_SITE_MODE!=="AIRPORT" ?
                  <Link
                    id="marina"
                    className={marinasClass}
                    to="/jerseymarinas/"
                    title="Jersey Marinas"
                  >
                    <span className=" button-left">
                      <span className="marinaflag"></span>
                      <p>Jersey Marinas</p>
                      <span className="button-right"></span>
                    </span>
                  </Link>
                  :
                  <a
                  id="marina"
                  className="nav-tab"
                  href={process.env.GATSBY_PORTS_URL+"jerseymarinas/"}
                  title="Jersey Marinas"
                  >
                  <span className=" button-left">
                    <span className="marinaflag"></span>
                    <p>Jersey Marinas</p>
                    <span className="button-right"></span>
                  </span>
                </a>
                }
              </li>
              <li>
                {process.env.GATSBY_SITE_MODE!=="AIRPORT" ?
                  <Link
                    id="coastguard"
                    className={coastguardClass}
                    to="/jerseycoastguard/"
                  >
                    <span className=" button-left">
                      <span className="coastguardflag"></span>
                      <p>Jersey Coastguard</p>
                      <span className="button-right"></span>
                    </span>
                  </Link>
                :
                  <a
                  id="coastguard"
                  className="nav-tab"
                  href={process.env.GATSBY_PORTS_URL+"jerseycoastguard/"}
                  >
                    <span className=" button-left">
                      <span className="coastguardflag"></span>
                      <p>Jersey Coastguard</p>
                      <span className="button-right"></span>
                    </span>
                  </a>
                }
              </li>
              <li>
                {process.env.GATSBY_SITE_MODE!=="AIRPORT" ?
                  <a
                  id="airport"
                  className="nav-tab"
                  href={process.env.GATSBY_AIRPORT_URL}
                  title="Jersey Airport"
                  >
                    <span className="button-left">
                      <span className="airportflag"></span>
                      <p>Jersey Airport</p>
                      <span className="button-right"></span>
                    </span>
                  </a>
                :
                  <Link
                  id="airport"
                  className="nav-tab active"
                  to="/"
                  title="Jersey Airport"
                  >
                    <span className="button-left">
                      <span className="airportflag"></span>
                      <p>Jersey Airport</p>
                      <span className="button-right"></span>
                    </span>
                  </Link>
                  }
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PageHeader