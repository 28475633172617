import { css } from "@emotion/core"

export const footerStyle = css`
  background: #434343;
  color: #ffffff;
  font-size: 11px;
  display: block;
  width: 100%;
  margin: 30px auto 0;
  line-height: 30px;
  height: 30px;
  @media (max-width: 600px) {
    margin: 0;
  }
  #footercontainer {
    padding: 0 10px;
    width: auto;
  }
  .footerlogo {
    height: 70px;
    background: #434343;
    color: #ffffff;
    font-size: 12px;
  }

  .SOJlogo {
    margin-top: 20px;
  }

  .footercol,
  .copyright {
    background: #434343;
    color: #ffffff;
    font-size: 12px;
    padding-right: 80px;
    margin: 0;
  }

  .copyright {
    padding-right: 0px;
  }

  div.copyright {
    a {
      &:visited,
      &:active {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .copyright {
    a {
      color:#fff;
      &:hover {
        color: #eee;
        text-decoration: underline;
      }
    }
  }
`
