import React, {useState} from "react"
import BtnMenu from "../images/btn_menu.png"
import BtnSearch from "../images/btn_search.png"
import { NavStyle } from '../style/navStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Links from '../components/Links'
import GetHarbourMenu from './GetHarbourMenu';
import GetPortsMenu from './GetPortsMenu';
import GetMarinasMenu from './GetMarinasMenu';
import GetCoastguardMenu from './GetCoastguardMenu';
import GetAirportMenu from './GetAirportMenu';

const NavBar= () => {
  const [menuToggle, setmenuToggle] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [fetchUrl, setFetchUrl] = useState("");
  const mediaMatch = useMediaQuery('(min-width:821px)');

  const urlString = `/results?q=${fetchUrl}`
 
  const toggleMenu = () => {
    setmenuToggle(!menuToggle);
  }

  const toggleSearchMenu = () => {
    setSearchToggle(!searchToggle);
  }

  const goToUrl = () => {
    window.location.href=urlString
  }

  let ports = false;
  let harbours = false;
  let marinas = false;
  let coastguard = false;
  let airport = false;
var currentURL = "";
  if (typeof window !== 'undefined') {
    currentURL=window.location.href; 
    if (window.location.href.indexOf("jerseycoastguard") !== -1)
      coastguard=true;
    else if (window.location.href.indexOf("jerseymarinas") !== -1)
      marinas=true;
    else if (window.location.href.indexOf("jerseyharbours") !== -1)
      harbours=true;
    else if (window.location.href.indexOf("jerseyairport") !== -1)
      airport=true;
    else
      ports=true;
  }

 
  if(process.env.GATSBY_SITE_MODE==="AIRPORT"){
    airport=true;
    ports=false;
  }
    

 
  return (
    <nav css={NavStyle} >
      <div id="mobilemenu" className="handheld">
        <button className="menuButtonStyle" onClick={toggleMenu}>
          <img alt="" src={BtnMenu} className="menuButtonStyleImage" />
        </button>      
      </div>
      <div className="handheld push-right" id="mobilesearch">
        <button className="menuButtonStyle" onClick={toggleSearchMenu}>
          <img alt="" src={BtnSearch} className="menuButtonStyleImage" />
        </button>     
      </div>
        {searchToggle  ? (
          <div id="mobilesearchbox" className="handheld">
          <input
            type="text"
            className="field"
            id="txtmobileSearch"
            name="txtmobileSearch"
            placeholder="Search..."
            style={{fontSize:"11px", paddingLeft:"5px"}}
            onChange={(event) => setFetchUrl(event.target.value)}
          />
          <button
            onClick={goToUrl}
            id="btnMobileSearch"
            name="btnMobileSearch"
          >
            Search
          </button>
        </div>
        ) : (
          null
        )}
       
        {menuToggle || mediaMatch  ? (
        <div id="mainlinks" className="col-1-1" style={{display:"block"}}>
          {ports && <GetPortsMenu/> }
          {harbours&& <GetHarbourMenu />}
          {marinas && <GetMarinasMenu/>}
          {airport && <GetAirportMenu/>}
          {coastguard  && <GetCoastguardMenu/>}
         
       </div>       
       ) : (
          null
        )}
  </nav>
  )
}
export default NavBar;