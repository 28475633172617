import React from "react"
import { headerStyle } from '../style/headerStyle';
const PageHeaderPDF = () => {
  return (
    <div id="PdfviewerPage" css={headerStyle}>
      <div className="grid">
        <div className="col-1-1 pdf_header" id="sitelogo">

        </div>        
      </div>
    </div>
  )
}

export default PageHeaderPDF
