import React from "react"
import { Link } from "gatsby"
import { footerStyle } from "../style/footerStyle"
const currentYear = new Date().getFullYear()

const Footer = () => {
  return (
    <footer css={footerStyle}>
      <div className="grid" id="footercontainer">
        <div className="copyright col-1-1">
        {process.env.GATSBY_SITE_MODE==="AIRPORT" ?
          <span className="push-left">&copy; Jersey Airport {currentYear}</span>
        :
          <span className="push-left">&copy;  Ports of Jersey {currentYear}</span>
        }         
          <Link
            to="/terms-and-conditions"
            className="push-right"
            style={{ paddingLeft: "10px" }}
          >
            Terms and conditions
          </Link>
          <Link
            to="/privacynotice"
            className="push-right"
          >
            Privacy notice
          </Link>
        </div>
      </div>
    </footer>
  )
}
export default Footer
