import { css } from "@emotion/core"
import menuArrow from "../images/menuarrow.png"

export const NavStyle = css`
  max-width: 100%;
  background: #00426a;
  color: #ffffff;
  font-size: 12px;
  border-bottom: 1px solid #ffffff;
  min-height: 55px;
  width: 100%;
  margin: 0 auto;

  @media print {
    display:none
  }
  
  

  #mainlinks {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    float: none;
    ul {
      padding: 0;
      margin: 0;
      .tertiary {
        background: #ededed;
        display: none;
        top: 35px;
        position: absolute;
        padding: 0;
        z-index: 1;
        width: 100vw;
        margin: 0 auto;
        max-width: 1140px;
      }
    }
    li {
      list-style-type: none;
      display: inline;
      border-right: 1px solid #0c5280;
      position: relative;
      &:hover ul.tertiary {
        display: block;
      }
    }
    a {
      color: #ffffff;
      text-decoration: none;
      padding-left: 12px;
      padding-right: 10px;
      font-size: 15px;
      display: inline-block;
      line-height: 55px;
      &:hover {
        text-decoration: underline;
        color: #ffffff;
      }
      &:visited {
        color: #ffffff;
      }
    }
    a.payOnline {
      // background-color:#db3832;
      background-color:#46a2d9;
    }
    // a.payOnlineHarbours {
    //   background-color:#bf7c6d;
    // }
    // a.payOnlineMarinas {
    //   background-color:#49a4b1;
    // }
    // a.payOnlineCoastguard {
    //   background-color:#efb93f;
    // }
    // a.payOnlineAirport {
    //   background-color:#46a2d9;
    // }

    span {
      margin-top: 15px;
      height: 25px;
      width: auto;
      border-right: 1px solid #004e7f;
      display: inline-block;
      &:last-child {
        border-right: 0px;
      }
    }
  }

  .handheld {
    display: none;
  }

  ul.tertiary li {
    display: inline-block;
    border: none;
  }

  #mainlinks {
    ul.tertiary li a,
    ul.tertiary li a:visited {
      color: #00426a;
      line-height: 50px;
      border: none;
    }
  }

  .menuButtonStyle {
    border: none;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
    height: 50px;
  }

  .menuButtonStyleImage {
    width: 60px;
    background-color: #00426a;
    border: none;
  }

  @media (max-width: 820px) {
    border-bottom: 5px solid #00b6bc;

    .button-left p {
      font-size: 12px;
    }

    #mainlinks {
      ul li {
        border-top: none;
        display: block;
        width: 100%;
        border: none;
        border-top: 1px solid #cdcdcd;
        background: #00426a;
      }
      li:hover ul.tertiary {
        display: none;
      }
      li ul.tertiary.current {
        position: relative;
        display: block;
        top: 0px;
      }
      ul.tertiary li {
        padding-left: 20px;
        background: rgb(0, 66, 106) url(${menuArrow}) no-repeat 10px center;
      }
    }

    a {
      width: 100%;
      box-sizing: border-box;
    }
  }

  @media (min-width: 820px) {
    .mainMenufirstChild {
      margin-left: 88px;
    }
  }

  @media (max-width: 906px) {
    #mainlinks a {
      font-size: 12px;
    }

    #sitelogo {
      padding: 0;
      margin: 0;
    }
    .sitebuttons {
      margin-top: 15px;
    }
  }

  @media (max-width: 820px) {
    #mainlinks {
      display: none;
      ul.tertiary.current {
        background: #ededed;
        display: block;
        position: relative;
        width: 100%;
      }
      ul.tertiary li a {
        &:visited {
          color: #fff;
        }
      }
    }

    #mobilemenu {
      float: none;
      height: 50px;
      width: 100%;
      a {
        &:visited,
        &:hover {
          color: #000;
          text-decoration: none;
        }
      }
      img {
        float: left;
        width: 60px;
      }
    }

    #mobilesearch {
      top: 0;
      position: absolute;
      right: 0;
      img {
        width: 60px;
        margin-top: 0px;
        padding-top: 2px;
      }
    }

    #mobilesearchbox {
      background-color: #0071b6;
      float: left;
      width: 100%;
      border: 1px #0071b6 solid;
    }

    #txtmobileSearch {
      border: 0;
      width: 78%;
      margin-left: 2%;
      height: 40px;
      background-color: #fff;
      float: left;
    }
    #btnMobileSearch {
      background-color: #0071b6;
      color: #fff;
      font-weight: bold;
      height: 40px;
      padding-top: 10px;
      padding-bottom: 5px;
      padding-left: 0px;
      padding-right: 0px;
      font-size: 1.2em;
      display: inline-block;
      width: 19%;
      text-align: center;
      text-transform: uppercase;
      min-width: 80px;
      float: right;
      border: 1px solid rgb(0, 113, 182);
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 422px) {
    #txtmobileSearch {
      width: 71%;
    }
    .button-left p {
      font-size: 12px;
    }
  }

  @media (max-width: 820px) {
    #mainlinks ul.tertiary li a,
    #mainlinks ul.tertiary li a:visited {
      color: #fff;
    }
    .handheld {
      display: block;
    }
  }
  /*little feet*/
  @media (min-width: 820px) {
  .lf_nav{
    background-color:#fff;
  }
  .lf_nav #mainlinks{
    text-align:center;
    ul{
      li{
        a{
          font-size:16px;
          color:#000;
          padding:0 20px;
        }
      }
    }
  }
}
`